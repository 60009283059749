export const dayAbbreviations = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const fullDayNameMap = {
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
  Sun: "Sunday",
};

export const weekDayMap = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 0, // Sunday is represented by 0 in Js
};

export const VARIABLE_TAX_RATE = "variable";

export const MAX_FIRST_NAME_LENGTH = 15;
export const LOCATION_PREFIXES = ["PL", "CL", "GL"];
export const LOCATION_PREFIXES_REGEX = new RegExp(
  LOCATION_PREFIXES.join("-|^")
);
export const AGNOSTIC_PROVIDER_NAME_TRUNCATE_LENGTH = 9;
export const SERVICE_BEHAVIOR_INTERNAL = "INTERNAL";
export const SERVICE_BEHAVIOR_EXTERNAL = "EXTERNAL";

export const SENTRY_POPUP_ENV = "development";
export const TIME_OF_DAY = [
  "12:00 AM – 2:00 AM",
  "2:00 AM – 4:00 AM",
  "4:00 AM – 6:00 AM",
  "6:00 AM – 8:00 AM",
  "8:00 AM – 10:00 AM",
  "10:00 AM – 12:00 PM",
  "12:00 PM – 2:00 PM",
  "2:00 PM – 4:00 PM",
  "4:00 PM – 6:00 PM",
  "6:00 PM – 8:00 PM",
  "8:00 PM – 10:00 PM",
  "10:00 PM – 12:00 AM",
];
export const DEFAULT_TIME_OF_DAY_INDICES = [4, 5, 6, 7, 8];
